import styled from "styled-components";

const UsersWrapper = styled.div`

`;

const Users = () => {
  return (
    <UsersWrapper>
      Users
    </UsersWrapper>
  )
}

export default Users;