import styled from "styled-components";

const ChannelsWrapper = styled.div`

`;

const Channels = () => {
  return (
    <ChannelsWrapper>
      Channels
    </ChannelsWrapper>
  )
}
export default Channels;