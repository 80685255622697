import styled from "styled-components";

const MessagesWrapper = styled.div`

`;

const Messages = () => {
  return (
    <MessagesWrapper>
      Messages
    </MessagesWrapper>
  )
}
export default Messages;